import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import descriptionSectionStyle from "assets/jss/material-kit-react/views/landingPageSections/descriptionSectionStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Let's Talk Book Merlin</h2>
            <img 
                src={require('pages/HomePage/img/multipleDevices.png')}
                className={classes.img}
            />
            <h5 className={classes.description}>
              This is the paragraph where you can write more details about your
              product. Keep you user engaged by providing meaningful
              information. Remember that by this time, the user is curious,
              otherwise he wouldn't scroll to get here. Add a button if you want
              the user to see more.
            </h5>
            
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(descriptionSectionStyle)(ProductSection);
